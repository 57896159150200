import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import { productData } from '../components/productData'
import {animate} from '../utilities/animateOpacity'
import {Link} from 'gatsby'

export default function ProductOverview({category}) {
    let products = null
    const allProducts = (category === 'All products')
    if (allProducts) {
        products = productData
    } else products = productData.filter(e => e.category === category)

    const data = useStaticQuery(
        graphql`
        query {
            allFile(filter: {relativeDirectory: {eq: "product-overview"}}) {
                edges {
                    node {
                        childImageSharp {
                            gatsbyImageData(placeholder: BLURRED)
                            fluid {
                                originalName
                            }
                        }
                    }
                }
            }
        }
        `
    )
    const imageData = data.allFile.edges

    products.forEach(el => {
        const correctImage = imageData[imageData.findIndex(i => i.node.childImageSharp.fluid.originalName === el.imageSrc)]
        el.image = correctImage.node
    });

    return animate(
        <div className="mt-6 grid grid-cols-2 gap-x-4 gap-y-10 sm:gap-x-6 md:grid-cols-4 md:gap-y-0 lg:gap-x-8">
            {products.map((product) => (
                <div key={product.id} className="group relative pb-4">
                    <div className="w-full h-56 bg-gray-200 rounded-sm overflow-hidden group-hover:opacity-75 lg:h-72 xl:h-80">
                        <GatsbyImage
                            image={getImage(product.image)}
                            alt={product.imageAlt}
                            className="w-full h-full object-center object-cover"
                        />
                    </div>
                    <h3 className="mt-4 text-sm text-gray-700">
                        <Link to={'/products/' + product.slug} state={{category: category}}>
                            <span className="absolute inset-0" />
                            {product.name}
                        </Link>
                    </h3>
                    {allProducts ? <p className="mt-1 text-sm text-gray-700">{product.category}</p> : null}
                    <p className="mt-1 text-sm font-medium text-gray-900">Explore</p>
                </div>
            ))}
        </div>
    )
}