import React, {Fragment} from 'react'
import { Transition } from '@headlessui/react'

export const animate = el => <Transition
        as={Fragment}
        show={true}
        appear={true}
        enter="duration-700 ease-in-out"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="duration-500 ease-in-out"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
    >{el}
    </Transition>