import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import {getImage, GatsbyImage} from 'gatsby-plugin-image'

const features = [
    { name: 'Embroidery', description: 'Patches, including twill patches and direct on garment embroidery.' },
    { name: 'Collars', description: 'Varied jacquard collars, light denim or chambray, and more.' },
    { name: 'Prints', description: 'Screen printing, heat transfer printing, plastisols transfers, sublimation printing, silicon or gel printing, glitter and gems, and more.' },
    { name: 'Washes', description: 'Pigment or bleach wash, tie-dye, stone wash, acid wash, enzyme wash, and more.' },
    { name: 'Finishing processes', description: 'Peach finish, ZERO WET treatment, enzyme finish, brushed finish, and more.' },
    { name: 'Packaging', description: 'Packaging, tags and care labels are provided respecting European recycling regulations.' },
  ]
  
  export default function SpecialFeatures() {

    const data = useStaticQuery(
      graphql`
      query {
        allFile(filter: {relativeDirectory: {eq: "special-features"}}) {
          edges {
              node {
                childImageSharp {
                  gatsbyImageData(
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                  fluid {
                    originalName
                  }
                }
              }
          }
        }
      }
      `
    )
    const images = data.allFile.edges
  
    return (
      <div>
        <div className="mx-auto py-8 px-4 grid items-center grid-cols-1 gap-y-16 gap-x-8 sm:px-6 sm:py-16 lg:max-w-7xl lg:px-8 lg:grid-cols-2">
          <div>
            <h2 className="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">Special Features</h2>
            <p className="mt-4 text-gray-700">
              We constantly scout for innovative designs and techniques to provide you with inspiration toolkits for your next collection. Below are just examples of what we can do. 
            </p>
  
            <dl className="mt-16 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 sm:gap-y-16 lg:gap-x-8">
              {features.map((feature) => (
                <div key={feature.name} className="border-t border-gray-200 pt-4">
                  <dt className="font-medium text-gray-900">{feature.name}</dt>
                  <dd className="mt-2 text-sm text-gray-700">{feature.description}</dd>
                </div>
              ))}
            </dl>
          </div>
          <div className="grid grid-cols-2 grid-rows-2 gap-4 sm:gap-6 lg:gap-8">
            {images.map((image, i) => (
              <GatsbyImage
                key={i}
                image={getImage(image.node)}
                alt="Special product features."
                className="bg-gray-100 rounded-sm"
              />
            ))}
          </div>
        </div>
      </div>
    )
  }