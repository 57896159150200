const productData = [
    {
        id: 11,
        name: 'T-Shirts',
        category: 'Knitwear',
        slug: 't-shirts',
        imageSrc: 't-shirts.jpg',
        imageAlt: 'Hand stitched, orange leather long wallet.',
        description: 'This group includes a vast range of items, including tank tops and short sleeved or long sleeved t-shirts. Usually our customers opt for a light GSM for this product group, staying on average around 120 to 170, depending on the season. It is a must have item in any brands’ fashion collection.',
        details: [
            {
                name: 'Raw Materials',
                description: 'Cotton, Pima cotton, organic cotton, linen, polyester, Lycra, modal, spandex, poly/cotton blend, recycled materials, and more.',
            },
            {
                name: 'Fabrics',
                description: 'Jersey, boyfriend wash solid, dana knit, Tencel organic cotton, microprint, yarn dyed cotton, and more.',
            },
            {
                name: 'Minimum Quantity',
                description: 'Our minimum quantity for t-shirts is 1,200 PCS per style and 400 PCS per color per style.',
            },
            {
                name: 'Lead time',
                description: 'Our lead time for t-shirts is around 100 to 150 days from order confirmation. ',
            },
        ],
    },
    {
        id: 12,
        name: 'Polo Shirts',
        category: 'Knitwear',
        slug: 'polo-shirts',
        imageSrc: 'polo-shirts.jpg',
        imageAlt: 'Hand stitched, orange leather long wallet.',
        description: 'Polo shirts are our strongest product. The GSM range is wide, going from lightweight fabrics of 120, up until the most heavy ones of 350. This is an item that can uptake much creative imagination, fostering impeccable details in the collars and plaquettes.',
        details: [
            {
                name: 'Raw Materials',
                description: 'Cotton, Pima cotton, organic cotton, linen, polyester, Lycra, modal, spandex, poly/cotton blend, recycled materials, and more.',
            },
            {
                name: 'Fabrics',
                description: 'Lacoste, pique, double pique, jersey, French Terry, Diagonal Terry, and more.',
            },
            {
                name: 'Minimum Quantity',
                description: 'Our minimum quantities for polo shirts is 1,200 PCS per style and 400 PCS per color per style.',
            },
            {
                name: 'Lead time',
                description: 'Our lead time for polo shirts is around 100 to 150 days from order confirmation.  ',
            },
        ],
    },
    {
        id: 13,
        name: 'Sweatshirts',
        category: 'Knitwear',
        slug: 'sweatshirts',
        imageSrc: 'sweatshirts.jpg',
        imageAlt: 'Hand stitched, orange leather long wallet.',
        description: 'This group includes hoodies and sweatshirts, with or without zippers. The fabric weight used depends on the season of the collection, and usually varies from 280 GSM to 380 GSM, with or without inner fleece layer.',
        details: [
            {
                name: 'Raw Materials',
                description: 'Cotton, Pima cotton, organic cotton, linen, polyester, Lycra, modal, spandex, poly/cotton blend, recycled materials, and more.',
            },
            {
                name: 'Fabrics',
                description: 'Jersey, boyfriend wash solid, dana knit, Tencel organic cotton, microprint, yarn dyed cotton, and more.',
            },
            {
                name: 'Minimum Quantity',
                description: 'Our minimum quantity for sweatshirts is 1,200 PCS per style and 400 PCS per color per style. ',
            },
            {
                name: 'Lead time',
                description: 'Our lead time for this product group is around 100 to 150 days from order confirmation. ',
            },
        ],
    },
    {
        id: 14,
        name: 'Sweatpants',
        category: 'Knitwear',
        slug: 'sweatpants',
        imageSrc: 'sweatpants.jpg',
        imageAlt: 'Hand stitched, orange leather long wallet.',
        description: 'This group includes all trousers used for comfort, such as joggers and shorts. The fabric weight utilized depends on the season of the collection, going up until 380 GSM, with or without a fleece inner layer.',
        details: [
            {
                name: 'Raw Materials',
                description: 'Cotton, Pima cotton, organic cotton, linen, polyester, Lycra, modal, spandex, poly/cotton blend, recycled materials, and more.',
            },
            {
                name: 'Fabrics',
                description: 'Jersey, boyfriend wash solid, dana knit, Tencel organic cotton, microprint, yarn dyed cotton, and more.',
            },
            {
                name: 'Minimum Quantity',
                description: 'Our minimum quantity for sweatpants is 1,200 PCS per style and 400 PCS per color per style. ',
            },
            {
                name: 'Lead time',
                description: 'Our lead time for this product group is around 100 to 150 days from order confirmation. ',
            },
        ],
    },
    {
        id: 15,
        name: 'Sweaters',
        category: 'Woven',
        slug: 'sweaters',
        imageSrc: 'sweaters.jpg',
        imageAlt: 'Hand stitched, orange leather long wallet.',
        description: 'This product type includes a vast range of items based on heavier fabrics, optimal for outerwear, such as dolcevitas, cardigans and pullovers. There are several factors that determine a sweater’s warmth, durability, and feel, it all depends on the use it is intended for.',
        details: [
            {
                name: 'Raw Materials',
                description: 'Cotton, wool, cashmere, synthetic materials, linen, and more.',
            },
            {
                name: 'Fabrics',
                description: 'Cable knit, buclé knit, ribbed, traditional, and more.',
            },
            {
                name: 'Minimum Quantity',
                description: 'Our minimum quantity for sweaters is 1,200 PCS per fabric per color.',
            },
            {
                name: 'Lead time',
                description: 'Our lead time for this product group is around 130 to 150 days from order confirmation.',
            },
        ],
    },
    {
        id: 21,
        name: 'Trousers',
        category: 'Woven',
        slug: 'trousers',
        imageSrc: 'trousers.jpg',
        imageAlt: 'Hand stitched, orange leather long wallet.',
        description: 'This product type includes jeans and other types of trousers, of long or short length. The possibilities in terms of styles are many, such as cargo, palazzo or even dungarees.',
        details: [
            {
                name: 'Raw Materials',
                description: 'Cotton, Cotton-polyester blend, elastan, linen, and more.',
            },
            {
                name: 'Fabrics',
                description: 'Chino, Corduroy, Cotton drill, Gabardine, Jacquard, Twill, Velvet, Bengaline, denim, and more.',
            },
            {
                name: 'Minimum Quantity',
                description: 'Our minimum quantity for trousers is 1,200 PCS per fabric per color.',
            },
            {
                name: 'Lead time',
                description: 'Our lead time for this product group is around 130 to 150 days from order confirmation.',
            },
        ],
    },
    {
        id: 22,
        name: 'Shirts',
        category: 'Woven',
        slug: 'shirts',
        imageSrc: 'shirts.jpg',
        imageAlt: 'Hand stitched, orange leather long wallet.',
        description: 'An essential in most fashion collections, the style of a shirt is strictly connected to the fabric type chosen. We offer a wide range of options in this regard, maintaining accurate sizing and attention to details to create products that stand out in their simplicity.',
        details: [
            {
                name: 'Raw Materials',
                description: 'Cotton, wool, cashmere, synthetic materials, linen, and more.',
            },
            {
                name: 'Fabrics',
                description: 'Flannel, Poplin, Oxford/pinpoint fabric, cotton sateen, broadcloth, chambray, corduroy, crossweave, cotton drill, gingham, madras, plaid, twill, lawn, herringbone, and more.',
            },
            {
                name: 'Minimum Quantity',
                description: 'Our minimum quantity for shirts is 1,200 PCS per fabric per color.',
            },
            {
                name: 'Lead time',
                description: 'Our lead time for this product group is around 130 to 150 days from order confirmation.',
            },
        ],
    },
    {
        id: 31,
        name: 'Leather goods',
        category: 'Accessories',
        slug: 'leather-goods',
        imageSrc: 'leather-goods.jpg',
        imageAlt: 'Hand stitched, orange leather long wallet.',
        description: 'Our leather goods selection is varied, ranging from elegant purses, to backpacks, belts and wallets. The raw material is chosen carefully from local suppliers, and thus it is processed into exquisite final products made to last.',
        details: [
            {
                name: 'Raw Materials',
                description: 'Leather',
            },
            {
                name: 'Minimum Quantity',
                description: 'There are no minimum quantities for this product group.',
            },
            {
                name: 'Lead time',
                description: 'Our lead time for this product group is around 50 days from order confirmation.',
            },
        ],
    },
    {
        id: 32,
        name: 'Others',
        category: 'Accessories',
        slug: 'others',
        imageSrc: 'others.jpg',
        imageAlt: 'Hand stitched, orange leather long wallet.',
        description: 'Whether you are looking for beanies to compliment your winter collection, baseball hats all year round or the perfect sports socks, we have the solution for you. We have a minimum quantity of 500 pieces for this product group and a lead time of around 90 to 120 days from order confirmation.',
    },
]

exports.productData = productData