import React from 'react'

export default function Breadcrumbs({ pages }) {
    return (
        <nav className="flex" aria-label="Breadcrumb">
            <ol role="list" className="flex items-center space-x-4">
                <li key={pages[0].name}>
                    <div className="flex items-center">
                        <p
                            onClick={pages[0].function}
                            className="text-sm font-medium text-gray-600 hover:text-gray-800 cursor-pointer"
                            aria-current={pages[0].current ? 'page' : undefined}
                        >
                            {pages[0].name}
                        </p>
                    </div>
                </li>
                {pages.slice(1).map((page) => (
                    < li key={page.name} >
                        <div className="flex items-center">
                            <svg
                                className="flex-shrink-0 h-5 w-5 text-gray-300"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                aria-hidden="true"
                            >
                                <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                            </svg>
                            {(page.function ?
                                <p
                                    onClick={page.function}
                                    className="ml-4 text-sm font-medium text-gray-600 hover:text-gray-800 cursor-pointer"
                                    aria-current={page.current ? 'page' : undefined}
                                >
                                    {page.name}
                                </p> :
                                <p
                                    className="ml-4 text-sm font-medium text-gray-600"
                                    aria-current={page.current ? 'page' : undefined}
                                >
                                    {page.name}
                                </p>
                            )}
                        </div>
                    </li>
                ))}
            </ol>
        </nav >
    )
}