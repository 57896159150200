import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import {getImage, GatsbyImage} from 'gatsby-plugin-image'

import {animate} from '../utilities/animateOpacity'


export default function CategoryOverview({categories, updateNavigation, scrollToRef }) {
    
    const data = useStaticQuery(
        graphql`
        query {
            knitwear: file(relativePath: {in: "knitwear.jpg"}) {
                childImageSharp {
                    gatsbyImageData(
                        placeholder: BLURRED
                        formats: [AUTO, WEBP, AVIF]
                      )
                }
            }
            woven: file(relativePath: {in: "woven.jpg"}) {
              childImageSharp {
                  gatsbyImageData(
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                }
            }
            accessories: file(relativePath: {in: "accessories.jpg"}) {
              childImageSharp {
                  gatsbyImageData(
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                }
            }
        }
        `
    )
    return animate(
        <div className="mt-6 grid grid-cols-1 gap-y-10 sm:grid-cols-2 sm:gap-y-0 sm:gap-x-6 lg:gap-x-8">
            {categories.map((category) => (
                <div key={category.id} className="group relative cursor-pointer" onClick={() => {updateNavigation(category.name); scrollToRef()}}>
                    <div className="w-full h-96 rounded-sm overflow-hidden group-hover:opacity-75 sm:h-auto sm:!aspect-w-5 sm:!aspect-h-4">
                        <GatsbyImage
                            image={getImage(data[category.name.toLowerCase()])}
                            alt={category.name}
                            className="w-full h-full object-center object-cover"
                        />
                    </div>
                    <h3 className="mt-4 text-base font-semibold text-gray-900">
                        <p>
                            <span className="absolute inset-0" />
                            {category.name}<span aria-hidden="true"> &rarr;</span>
                        </p>
                    </h3>
                    <p className="mt-1 text-sm text-gray-700">{category.description}</p>
                </div>
            ))}
        </div>
    )
}