import React, { useState, useCallback } from 'react'

import CategoryOverview from './categoryOverview'
import ProductOverview from './productOverview'
import Breadcrumbs from './breadcrumbs'

import { animate } from '../utilities/animateOpacity'

export default function ProductPalette({categories, title, myRef, scrollToRef, activeNavigation}) {

    const [navigation, setNavigation] = useState(activeNavigation)

    const multipleCategories = categories.length > 1

    const wrapperSetParentState = useCallback(val => {
        setNavigation(val)
    }, [setNavigation])

    return (
        <div>
            <div ref={myRef} className="max-w-7xl mx-auto py-16 px-4 sm:pt-24 mt-8 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-baseline sm:justify-between">
                    <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                        {title}
                    </h2>
                    {/* {navigation === 'All products' || !multipleCategories ? null : <p className="hidden text-sm font-semibold text-gray-700 hover:text-gray-900 sm:block cursor-pointer" onClick={() => {setNavigation('All products'); scrollToRef()}}>
                        Browse all products<span aria-hidden="true"> &rarr;</span>
                    </p>} */}
                </div>
                {(navigation !== 'Overview' && multipleCategories ?
                    animate(<div className='pt-2'><Breadcrumbs pages={[{ name: 'Back', function: () => {setNavigation('Overview'); scrollToRef()}}, { name: navigation }]} /></div>)
                    : null)}
                {(navigation === 'Overview' && multipleCategories ?
                    <CategoryOverview categories={categories} updateNavigation={wrapperSetParentState} scrollToRef={scrollToRef}/>
                    :
                    <ProductOverview category={navigation} activeNavigation={activeNavigation} updateNavigation={wrapperSetParentState} />)}
                {/* {navigation === 'All products' ? null : <div className="mt-6 sm:hidden">
                    <p className="block text-sm font-semibold bg-primary-300 hover:bg-primary-500 text-gray-900 w-fit px-2 py-1" onClick={() => {setNavigation('All products'); scrollToRef()}}>
                        Browse all products<span aria-hidden="true"> &rarr;</span>
                    </p>
                </div>} */}
            </div>
        </div>
    )
}