export const categoryData = [
    {
        id: 1,
        name: 'Knitwear',
        description: 'Knitting involves interloping or interlacing a single yarn or thread, which results in very stretchable and comfortable fabrics, used historically for more comfortable fashion such as t-shirts, sweatshirts and sweatpants.',
        source: "inhouse",
    },
    {
        id: 2,
        name: 'Woven',
        description: 'Woven fabrics are created using several warps, or longitudinal yarns and wefts, resulting in rigid and more durable fabrics. The clothing produced with this type of fabrics include shirts, jeans and sweaters.',
        source: "outsourced",
    },
    {
        id: 3,
        name: 'Accessories',
        description: 'Accessories in our range of production include elegant leather accessories, produced with premium local materials and exquisite craftsmanship. These are accompanied by a selection of hats and socks.',
        source: "outsourced",
    },
]