import React, {useEffect, useRef} from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import SpecialFeatures from "../components/specialFeatures"
import Production from "../components/production"
import ProductIntro from "../components/productIntro"
import { categoryData } from '../components/categoryData'
import ProductOverview from "../components/productOverview"
import ProductPalette from "../components/productPalette"

const ProductsPage = ({location}) => {

    const myRef = useRef(null)

    const executeScroll = () => myRef.current.scrollIntoView({behavior: 'smooth'})

    const categories = categoryData.filter(el => el.source === "inhouse")

    useEffect(() => {
        if (!location.state ) return
      if (location.state.scrollToProducts) executeScroll()
    }, []);

    return (
        <Layout>
            <Seo title='Sara Fashionwear' />
            <ProductIntro scrollToRef={executeScroll}/>
            <ProductPalette myRef={myRef} categories={categories} title={"Our products"} scrollToRef={executeScroll} activeNavigation={ (!location.state || location.state.category === undefined) ? categories[0].name : location.state.category}/>
            <div className="max-w-7xl mx-auto pb-8 sm:pb-4 mt-8 -px-4 sm:-px-6 lg:px-8">
                <div className="bg-gray-100 rounded-sm">
                <div className="mx-auto max-w-7xl py-12 px-4 sm:px-6 lg:flex lg:items-center lg:justify-between lg:py-24 lg:px-8">
                    <h2 className="tracking-tight text-gray-900 md:text-4xl">
                    <span className="text-2xl md:text-3xl font-bold block">Don’t see what you’re looking for?</span>
                    <span className="mt-1 text-lg md:text-xl font-medium block text-green-700">Visit our sourcing services for woven items, leather accessories and more.</span>
                    </h2>
                    <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0">
                    <div className="inline-flex rounded-md shadow">
                        <Link to="/sourcing"
                                className="inline-flex items-center justify-center rounded-sm border border-transparent bg-dark-500 hover:bg-dark-700 px-5 py-3 text-base font-medium text-white"
                                >Explore sourcing
                        </Link>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            <SpecialFeatures />
            <Production />
        </Layout>
    )
}

export default ProductsPage