import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import {getImage, GatsbyImage} from 'gatsby-plugin-image'

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }
  
  export default function Production() {
    const data = useStaticQuery(
        graphql`
        query {
            embroidery: file(relativePath: {in: "embroidery.jpg"}) {
                childImageSharp {
                    gatsbyImageData(
                        placeholder: BLURRED
                        formats: [AUTO, WEBP, AVIF]
                      )
                }
            }
            collar: file(relativePath: {in: "threads.jpg"}) {
              childImageSharp {
                  gatsbyImageData(
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                }
            }
        }
        `
    )

    const sections = [
        {
            name: 'Special Machinery',
            description:
                'We have created different units to operate embroidery and collar/cuff machines to compliment the wide range of knitwear and woven machinery. This has proven to increase our rate of production, speeding processes throughout the supply chain.',
            imageSrc: getImage(data.embroidery),
            imageAlt: 'Embroidery machine.',
        },
        {
            name: 'Raw Material Sourcing',
            description:
                'Sara Fashionwear carefully chooses the sources of raw materials across the product line, as quality begins here. We are able to offer clients items from other countries that surpass local standards, such as zippers and other accessories from China.',
            imageSrc: getImage(data.collar),
            imageAlt: 'Yarn threads.',
        },
      ]

    return (
      <div >
        <div className="max-w-2xl mx-auto py-24 px-4 sm:px-6 sm:py-32 lg:max-w-7xl lg:px-8">
          <div className="max-w-3xl mx-auto text-center">
            <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">We invest in technology and people</h2>
            <p className="mt-4 text-gray-700">
              With a workforce of more than 1,000 people, the company follows a semi-vertically integrated structure, guaranteeing continuity in product quality and maximized efficiency. 
            </p>
          </div>
  
          <div className="mt-16 space-y-16">
            {sections.map((feature, featureIdx) => (
              <div
                key={feature.name}
                className="flex flex-col-reverse lg:grid lg:grid-cols-12 lg:gap-x-8 lg:items-center"
              >
                <div
                  className={classNames(
                    featureIdx % 2 === 0 ? 'lg:col-start-1' : 'lg:col-start-8 xl:col-start-9',
                    'mt-6 lg:mt-0 lg:row-start-1 lg:col-span-5 xl:col-span-4'
                  )}
                >
                  <h3 className="text-lg font-medium text-gray-900">{feature.name}</h3>
                  <p className="mt-2 text-sm text-gray-700">{feature.description}</p>
                </div>
                <div
                  className={classNames(
                    featureIdx % 2 === 0 ? 'lg:col-start-6 xl:col-start-5' : 'lg:col-start-1',
                    'flex-auto lg:row-start-1 lg:col-span-7 xl:col-span-8'
                  )}
                >
                  <div className="!aspect-w-5 !aspect-h-2 rounded-sm bg-gray-100 overflow-hidden">
                    <GatsbyImage image={feature.imageSrc} alt={feature.imageAlt} className="object-center object-cover" />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }