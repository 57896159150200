import React from 'react'
import { StaticImage } from "gatsby-plugin-image"

export default function ProductIntro({scrollToRef}) {
    return (
      <div className=" -mt-24 border-b hidden sm:block border-gray-500">
        <div className="relative">
          {/* Decorative image and overlay */}
          <div aria-hidden="true" className="absolute inset-0 overflow-hidden">
            <StaticImage src="../images/productIntro.jpg" objectPosition='right center' alt="Smiling model" className="w-full h-full"/>
          </div>
          <div aria-hidden="true" className="absolute inset-0 bg-gradient-to-r via-transparent from-white opacity-70" />
          <div className="relative max-w-7xl mx-auto py-32 pl-6 flex flex-col text-left items-left sm:py-64">
            <h1 className="hidden w-1/2 md:w-2/3 sm:block text-4xl font-extrabold tracking-tight text-gray-900 lg:text-5xl">We make your ideas work</h1>
            <p className="mt-4 w-1/2 lg:w-2/3 text-xl hidden sm:block text-gray-700">
              Live the comfort of quality continuity across a wide selection of clothing items for your brand and a customer service to match it.
            </p>
            <p 
                className="absolute -bottom-6 cursor-pointer self-center bg-primary-500 border border-transparent rounded-sm py-3 px-8 text-base font-medium text-gray-900 hover:bg-primary-700"
                onClick={() => scrollToRef()}
            >
              Explore our products
            </p>
          </div>
        </div>
      </div>
    )
  }